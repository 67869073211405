type Contact = {
  contactType: string;
  contactValue: string;
};

type GetOrganization = {
  organizationID: number;
  organizationName: string;
  contacts: Contact[];
};

export type GetPersonType = {
  personTypeID: number;
  personTypeName: string;
};

export type Application = {
  externalWebApplicationDesc: string;
  webApplicationDesc: string;
  webApplicationIcon: string;
  webApplicationId: number;
  webApplicationName: string;
  webApplicationUrl: string;
  webApplicationBackground: string;
};

export type UserProfile = {
  personInfo: {
    personID: number;
    firstName: string;
    lastName: string;
    middleInitial: string;
    primaryLanguage: string;
    dateOfBirth: string;
    age: number;
    gender: string;
    email: string;
    primaryPhoneNumber: string;
  };
  personAddresses: {
    homeAddress: PersonAddress;
    mailingAddress: PersonAddress;
  };
  personContact: {
    homePhone: string;
    workPhone: string;
    homeEmailAddress: string;
    workEmailAddress: string;
  };
};

export type PersonAddress = {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
};

export type MemberProfile = UserProfile & {
  healthCareId: string;
  medicalNumber: string;
};

export type MemberProfileState = {
  profile: MemberProfile;
  loading: boolean;
};

export function getEmptyUserProfile() {
  return {
    personInfo: {
      personID: 0,
      firstName: "",
      lastName: "",
      middleInitial: "",
      primaryLanguage: "",
      dateOfBirth: "",
      age: 0,
      gender: "",
      email: "",
      primaryPhoneNumber: "",
    },
    personAddresses: {
      homeAddress: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
      },
      mailingAddress: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
      },
    },
    personContact: {
      homePhone: "",
      workPhone: "",
      homeEmailAddress: "",
      workEmailAddress: "",
    },
  };
}

export type UserProfileState = {
  profile: UserProfile;
  loading: boolean;
};
