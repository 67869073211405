import Axios from "axios";
import * as appSettings from "./AppSettingsService";

export async function getUserApplications(): Promise<any> {
  try {
    const response = await Axios.get(
      `${appSettings.getAppApiUrl()}/personorg/person/v2/WebApps`
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
}

export function getUserProfile(): Promise<any> {
  return Axios.get(`${appSettings.getAppApiUrl()}/personorg/person/v1/Summary`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getPersonType(): Promise<any> {
  try {
    const response = await Axios.get(
      `${appSettings.getAppApiUrl()}/personorg/person/v1/PersonType`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getMemberDetails(personId: number): Promise<any> {
  const config = {
    params: { personId },
  };
  try {
    const response = await Axios.get(
      `${appSettings.getAppApiUrl()}/member/v1/GetMemberDetails`,
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getPersonOrganizations(): Promise<any> {
  try {
    const response = await Axios.get(
      `${appSettings.getAppApiUrl()}/personorg/organization/v1/Organization`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
