import React, { useState, useEffect, SyntheticEvent } from "react";
import { Redirect, Route, Switch, HashRouter } from "react-router-dom";
import * as authService from "../../services/AuthService/AuthService";
import * as AppSettingsService from "../../services/AppSettingsService";
import * as appWindowService from "../../services/AppWindowService";
import { AppNavigation } from "@mtm/components";
import HeaderLayout from "../HeaderLayout/HeaderLayout";
import FooterLayout from "../FooterLayout/FooterLayout";

import styles from "./DefaultLayout.module.scss";
// routes config
import routes from "../../routes";
import mtmLogo from "../../assets/images/mtm-logo.png";
import revealLogo from "../../assets/images/reveal_logo.png";
import { UserProvider } from "../../context/UserProvider";
import { Layout } from "antd";
import * as userApi from "../../services/UserService";
import ReactGA from "react-ga";
import { Application } from "../../types/ApplicationTypes";

// type Props = StateProps & DispatchProps;

export function DefaultLayout() {
  const [isMtmTransitContext, setIsMtmTransitContext] = useState<
    boolean | null
  >(null);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isSigninRedirectBack, setIsSigninRedirectBack] = useState<boolean>(
    false
  );
  const [isUserAuthenticated, setIsUserAuthenticated] = useState<boolean>(
    false
  );
  const [apps, setApps] = useState<Application[]>([]);

  const [
    userRegistrationNotCompleted,
    setuserRegistrationNotCompleted,
  ] = useState<boolean>(false);
  const [orgCode, setOrgCode] = useState("");
  const documentLink = AppSettingsService.getExternalLinks()["s3Documents"];

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener(
        "storage",
        authService.shareActiveBrowserInfo,
        false
      );
    }
    localStorage.setItem("getActiveBrowerInfo", "true");
    localStorage.removeItem("getActiveBrowerInfo");

    if (!sessionStorage.getItem("MTMLink-GA-trigged")) {
      ReactGA.pageview("mtmlink");
      sessionStorage.setItem("MTMLink-GA-trigged", "true");
    }

    if (appWindowService.isMtmTransitUrl()) {
      appWindowService.setMtmTransitContext();
      setOrgCode(appWindowService.getOrgCode);
    } else {
      appWindowService.removeMtmTransitContext();
    }

    authorize();
  }, []);

  function toggle() {
    setIsCollapsed(!isCollapsed);
  }

  function useBackupLogo(e: SyntheticEvent<HTMLImageElement, Event>) {
    e.currentTarget.src = revealLogo;
  }

  async function authorize() {
    setTimeout(() => {
      setIsMtmTransitContext(appWindowService.isMtmTransitContext());
    });

    const loggingOut = authService.isLogout();

    const authenticated = await authService.isAuthenticated();

    if (authenticated) {
      if (loggingOut) {
        await authService.logout();
        return;
      } else {
        setIsUserAuthenticated(true);

        if (!loggingOut) {
          userApi.getUserApplications().then((resp) => {
            if (resp.length === 0) {
              setuserRegistrationNotCompleted(true);
            } else if (resp.length === 1) {
              const appUrl = resp[0].webApplicationUrl;
              if (appUrl) {
                window.location.href = appUrl;
              }
            } else {
              window.location.href = appWindowService.isMtmTransitUrl()
                ? `${window.location.origin}/#/home?source=mtmtransit`
                : "/#/home";
            }
            setApps(resp);
          });
        }
      }
    } else {
      if (authService.isSigninRedirectBack()) {
        setIsSigninRedirectBack(true);
      } else {
        await authService.signIn();
      }
    }
  }

  function loading() {
    return (
      <div className="animated fadeIn pt-1 text-center">
        {/* <Spinner type="grow" color="secondary" /> */}
      </div>
    );
  }

  return (
    <HashRouter>
      {isUserAuthenticated ? (
        <>
          {apps.length > 1 ? (
            <Layout className={styles.app}>
              <UserProvider>
                <HeaderLayout
                  isCollapsed={isCollapsed}
                  handler={toggle}
                  applications={apps}
                />
                <div className={styles["app--body"]}>
                  <main className={styles["main"]}>
                    <React.Suspense fallback={loading()}>
                      <Switch>
                        {routes.map((route: any, idx: any) => {
                          return route.component ? (
                            <Route
                              key={idx}
                              path={route.path}
                              exact={route.exact}
                              render={(props) => <route.component {...props} />}
                            />
                          ) : null;
                        })}
                        <Redirect from="/" to="/home" />
                      </Switch>
                    </React.Suspense>
                  </main>
                </div>
                <FooterLayout />
              </UserProvider>
            </Layout>
          ) : (
            <>
              {userRegistrationNotCompleted && (
                <Layout className={styles.app}>
                  <div className={styles.headerContainer}>
                    <AppNavigation isDefault={false} webApps={[]} />
                  </div>

                  <div className={styles["app--body"]}>
                    <div className={styles.loading}>
                      <div className={styles.errorMsg}>
                        <p>
                          We're sorry, we were not able to complete your
                          registration.
                        </p>

                        <p>
                          If you are a Health Plan member in need of
                          Non-Emergency Medical Transportation, please contact
                          MTM at <a href="tel:888-597-1189">888-597-1189</a> so
                          that we may assist you.
                        </p>

                        <p>
                          If you are a Transportation Provider, please contact
                          your MTM Vendor Account Manager to assist you.
                        </p>
                      </div>
                    </div>
                  </div>
                </Layout>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {isSigninRedirectBack ? (
            <Layout className={styles.app}>
              <div className={styles["app--body"]}>
                <main className={styles["main"]}>
                  <React.Suspense fallback={loading()}>
                    <Switch>
                      {routes
                        .filter((route) => route.name === "Redirect")
                        .map((route: any, idx: any) => {
                          return route.component ? (
                            <Route
                              key={idx}
                              path={route.path}
                              exact={route.exact}
                              render={(props) => <route.component {...props} />}
                            />
                          ) : null;
                        })}

                      <Redirect from="/" to="/redirect" />
                    </Switch>
                  </React.Suspense>
                </main>
              </div>
            </Layout>
          ) : (
            <div className={styles.loading}>
              <p className={styles.imageWrapper}>
                {isMtmTransitContext === true ? (
                  <img
                    alt="Reveal logo"
                    src={`${documentLink}/transit-logos/${orgCode}.PNG`}
                    onError={useBackupLogo}
                  />
                ) : (
                  <>
                    {isMtmTransitContext === false && (
                      <img alt="link banner" src={mtmLogo} />
                    )}
                  </>
                )}
              </p>
            </div>
          )}
        </>
      )}
    </HashRouter>
  );
}
