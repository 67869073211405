import { Log, UserManager } from "oidc-client";
import * as appSettings from "../AppSettingsService";
import * as appWindowService from "../AppWindowService";

// NOTE: These key and value must be the same for all the MTM Web apps
const ACTIVE_BROWSER_SESSION_KEY: string = "MTM-Link_active-browser";
const ACTIVE_BROWSER_SESSION_VALUE: string = "true";
const ACTIVE_USER: string = "MTM-Active-User";
export let user: any;

let oouthCOdeFlowSettings = appSettings.getOauthCodeFlowSettings();

if (appWindowService.isMtmTransitUrl()) {
  oouthCOdeFlowSettings[
    "redirect_uri"
  ] = `${oouthCOdeFlowSettings["redirect_uri"]}?source=mtmtransit`;
}

const userManager = new UserManager(oouthCOdeFlowSettings);

Log.logger = console; // TODO Logging for development only
handlerUserLoadedEvent();

handlerUserSignoutEvent();

export async function signinRedirect() {
  try {
    // Note: Force state renewal for the redirect page
    if (isActiveBrowserSession() || isIdpRedirect()) {
      return await _signinRedirect();
    } else {
      const sessioninfo = await userManager.querySessionStatus();
      if (sessioninfo.session_state && sessioninfo.sub) {
        await logout();
      } else {
        return await _signinRedirect();
      }
    }
  } catch (error) {
    return await _signinRedirect();
  }
}

export async function signinRedirectCallback() {
  try {
    user = await userManager.signinRedirectCallback();
    sessionStorage.setItem(ACTIVE_USER, JSON.stringify(user));
    return user;
  } catch (error) {
    console.log("OauthCodeFlowService->signinRedirectCallback error", error);
    throw error;
  }
}

export function userAvailable() {
  return JSON.parse(sessionStorage.getItem(ACTIVE_USER)!);
}

export async function getUser() {
  if (user) {
    sessionStorage.setItem(ACTIVE_USER, JSON.stringify(user));
    return user;
  }
  user = await userManager.getUser();
  sessionStorage.setItem(ACTIVE_USER, JSON.stringify(user));
  return user;
}

function handlerUserLoadedEvent() {
  userManager.events.addUserLoaded(() => {
    userManager.getUser().then((userResp: any) => {
      user = userResp;
      sessionStorage.setItem(ACTIVE_USER, JSON.stringify(user));
    });
  });
}

export async function renewToken() {
  user = await userManager.signinSilent();
  sessionStorage.setItem(ACTIVE_USER, JSON.stringify(user));
  return user;
}

export async function logout() {
  user = await getUser();

  if (user) {
    await userManager.signoutRedirect({ id_token_hint: user.id_token });
    await userManager.removeUser();
    user = null;
  } else {
    await userManager.signoutRedirect();
  }
}

function handlerUserSignoutEvent() {
  userManager.events.addUserSignedOut(async () => {
    user = await getUser();

    if (user) {
      await userManager.signoutRedirect({ id_token_hint: user.id_token });
      await userManager.removeUser();
      user = null;
    }
  });
}

export function isIdpRedirect() {
  return window.location.href.includes("/#/redirect");
}

export function isIdpLogout() {
  return window.location.href.includes("/#/logout");
}

export function isActiveBrowserSession() {
  return (
    window.sessionStorage.getItem(ACTIVE_BROWSER_SESSION_KEY) ===
    ACTIVE_BROWSER_SESSION_VALUE
  );
}

export function activeBrowserSession() {
  window.sessionStorage.setItem(
    ACTIVE_BROWSER_SESSION_KEY,
    ACTIVE_BROWSER_SESSION_VALUE
  );
}

async function _signinRedirect() {
  await userManager.signinRedirect({
    extraQueryParams: {
      state_timestamp: new Date().getTime(),
    },
  });
}
