import React from "react";

const Profile = React.lazy(() => import("./pages/ProfilePage/ProfilePage"));
const Redirect = React.lazy(() => import("./pages/RedirectPage/RedirectPage"));
const HomePage = React.lazy(() => import("./pages/HomePage/HomePage"));
const Logout = React.lazy(() => import("./pages/LogoutPage/LogoutPage"));

const routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/home", exact: true, name: "Landing Page", component: HomePage },
  { path: "/profile", exact: true, name: "Profile", component: Profile },
  { path: "/redirect", exact: true, name: "Redirect", component: Redirect },
  { path: "/logout", exact: true, name: "Logout", component: Logout },
];

export default routes;
