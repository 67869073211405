import axios from 'axios';
import * as AuthService from './AuthService';

export default {
  useInterceptor: () => {
    const authService = AuthService;
    axios.interceptors.request.use(
      requestConfig => {
        const token = authService.getLinkToken();
        requestConfig.headers.Authorization = `Bearer ${token}`;
        return requestConfig;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }
};
