import React from "react";
import Header from "../../components/Header/Header";

import styles from "./HeaderLayout.module.scss";

import { Application } from "../../types/ApplicationTypes";
import { useUserState } from "../../context/UserProvider";

type HeaderLayoutProps = {
  isCollapsed: any;
  handler: any;
  applications: Application[];
};

export default function HeaderLayout(props: HeaderLayoutProps) {
  const userState = useUserState();
  const { isCollapsed, handler, applications } = props;

  return (
    <div className={styles.headerContainer}>
      <Header
        isCollapsed={isCollapsed}
        applications={applications}
        handler={handler}
        profile={userState.userProfile}
        organizations={userState.organizations}
      ></Header>
    </div>
  );
}
