declare global {
  interface Window {
    // add you custom properties and methods
    __env: object;
  }
}
const browserWindow = window || {};
const appSettings: any = browserWindow.__env || {};

export function getOauthCodeFlowSettings() {
  return appSettings.OAUTH_CODE_FLOW_SETTINGS;
}

export function getAppDomain() {
  return appSettings.APP_DOMAIN;
}

export function getAppApiUrl() {
  return appSettings.APP_API_URL;
}

export function getApiMockEnabled() {
  return appSettings.API_MOCK_ENABLED;
}

export function getGtag() {
  return appSettings.GTAG;
}

export function getExternalLinks() {
  return appSettings.externalLinks;
}
