import React from "react";
import { AppNavigation } from "@mtm/components";
import { UserProfile } from "../../types/UserTypes";
import { Application } from "../../types/ApplicationTypes";
import { Organization } from "../../types/MemberTypes";
import * as AuthService from "../../services/AuthService/AuthService";

type OwnProps = {
  isCollapsed: any;
  handler: any;
  applications: Application[];
  profile: UserProfile;
  organizations: Organization[];
};

const authService = AuthService;

function Header(props: OwnProps) {
  const userLinks = [
    {
      id: "user-profile",
      display: "User Profile",
      icon: "",
      link: "#/profile",
    },
    {
      id: "help",
      display: "Help",
      icon: "",
      link: "",
    },
    {
      id: "signout",
      display: "Sign Out",
      icon: "",
      link: "",
    },
  ];

  function getAppUrl(appId: number) {
    const app = applications.find((obj) => obj.webApplicationId === appId);
    return app ? app.webApplicationUrl : "";
  }

  function handleWebAppSelected(appId: number) {
    const appUrl = getAppUrl(appId);

    if (appUrl) {
      window.location.href = appUrl;
    }
  }

  function handleUserLinkSelected(linkId: string) {
    if (linkId === "signout") {
      authService.logout();
    } else {
      const userLink = userLinks.find((obj) => obj.id === linkId);
      if (userLink) {
        window.location.hash = userLink.link;
      }
    }
  }

  const { profile, applications, organizations } = props;
  const userProfile = {
    lastName: profile.personInfo.lastName,
    firstName: profile.personInfo.firstName,
    role: organizations.length > 0 ? organizations[0].organizationName : "",
  };
  return (
    <AppNavigation
      isDefault={true}
      profile={userProfile}
      userLinks={userLinks}
      webApps={applications}
      onUserLinkSelection={handleUserLinkSelected}
      onWebAppSelected={handleWebAppSelected}
    />
  );
}

export default Header;
