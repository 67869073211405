import React, { useEffect } from "react";
import InterceptorService from "./services/AuthService/InterceptorService";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { DefaultLayout } from "./containers/DefaultLayout/DefaultLayout";
import ReactGA from "react-ga";
import { getGtag } from "../src/services/AppSettingsService";

ReactGA.initialize(getGtag());

const loading = () => <div>Loading...</div>;

function App() {
  useEffect(() => {
    InterceptorService.useInterceptor();
  }, []);

  return (
    <Router>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route exact path="/" render={(props) => <DefaultLayout />} />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default App;
