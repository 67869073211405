
import * as appSettings from "./AppSettingsService";

const MTM_TRANSIT_COOKIE_NAME = "mtmtransit";

const MTM_TRANSIT_ORG_CODE_COOKIE_NAME = "transitOrgCode";


// check  if the user was redirected to MTM UI Gateway from revealapps.net or the browser tab's session has mtmtransit=true
export function isMtmTransitUrl() {
    return (
      window.location.search.includes("source=mtmtransit") ||
      window.location.hash.includes("source=mtmtransit")
    );
}


function getCookie(name: string) {
    let cookie: { [key: string]: string } = {};
    document.cookie.split(";").forEach(function(el) {
      let [k, v] = el.split("=");
      cookie[k.trim()] = v;
    });
    return cookie[name];
  }
  
  function setCookie(cookieName: string, cookieValue: string) {
    const appDomain = appSettings.getAppDomain();
    document.cookie = `${cookieName}=${cookieValue};domain=${appDomain};path=/;`;
  }
  
  function deleteCookie(cookieName: string) {
    document.cookie = cookieName + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }
  
  
  export function isMtmTransitContext() {
    const mtmTransitCookie = getCookie("mtmtransit");
    return mtmTransitCookie === "true";
  }
  
  export function setMtmTransitContext() {
    setCookie(MTM_TRANSIT_COOKIE_NAME, "true");
    const params = new URLSearchParams(window.location.search);
    const orgCode = params.get("orgCode");
    if (orgCode !== null) {
      setCookie(MTM_TRANSIT_ORG_CODE_COOKIE_NAME, orgCode);
    }
  }
  
  export function getOrgCode() {
    return getCookie("transitOrgCode");
  }
  
  export function removeMtmTransitContext() {
    setCookie(MTM_TRANSIT_COOKIE_NAME, "false");
    deleteCookie(MTM_TRANSIT_COOKIE_NAME);
    setCookie(MTM_TRANSIT_ORG_CODE_COOKIE_NAME, "")
    deleteCookie(MTM_TRANSIT_ORG_CODE_COOKIE_NAME)
  }
  