import React, { useEffect, useState, useContext, createContext } from "react";
import * as userApi from "../services/UserService";
import { isLogout } from "../services/AuthService/AuthService";
import {
  getEmptyUserProfile,
  Application,
  UserProfile,
} from "../types/UserTypes";
import { Organization } from "../types/MemberTypes";

type UserProviderProps = { children: React.ReactNode };

type UserStateContextType = {
  userProfile: UserProfile;
  applications: Application[];
  organizations: Organization[];
  loading: boolean;
};

const UserStateContext = createContext<UserStateContextType | undefined>(
  undefined
);

const UserDispatchContext = createContext<any | undefined>(undefined);

function UserProvider({ children }: UserProviderProps) {
  const [userState, setUserState] = useState({
    userProfile: getEmptyUserProfile(),
    applications: [],
    organizations: [],
    loading: true,
  });

  useEffect(() => {
    async function getUserProfile() {
      const resp = await userApi.getUserProfile();
      setUserState((userState) => ({ ...userState, userProfile: resp }));
    }

    async function getUserOrganizations() {
      const orgs = await userApi.getPersonOrganizations();
      setUserState((userState) => ({ ...userState, organizations: orgs }));
    }

    async function getUserApplications() {
      const resp = await userApi.getUserApplications();
      setUserState((userState) => ({
        ...userState,
        applications: resp,
        loading: false,
      }));
    }

    getUserProfile();
    getUserOrganizations();
    getUserApplications();
  }, []);

  return (
    <UserStateContext.Provider value={userState}>
      <UserDispatchContext.Provider value={setUserState}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch };
