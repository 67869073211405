import * as OauthCodeFlowService from "./OauthCodeFlowService";

const oauthCodeFlow = OauthCodeFlowService;

export function logout() {
  sessionStorage.clear();
  oauthCodeFlow.logout();
}

export async function isAuthenticated() {
  let user = await oauthCodeFlow.getUser();
  if (user) {
    if (user.expired) {
      try {
        user = await oauthCodeFlow.renewToken();
      } catch (error) {
        console.log("AuthService->sAuthenticated() renewToken error", error);
      }
    }
  }
  return !!(oauthCodeFlow.isActiveBrowserSession() && user && !user.expired);
}

export function isSigninRedirectBack() {
  return oauthCodeFlow.isIdpRedirect();
}

export async function handleSigninRedirectCallback() {
  let user;
  try {
    user = await oauthCodeFlow.signinRedirectCallback();
    if (user) {
      oauthCodeFlow.activeBrowserSession();
    }
    return user;
  } catch (error) {
    throw error;
  }
}

export function isLogout() {
  return oauthCodeFlow.isIdpLogout();
}

export function getUserRole() {
  const tokenResult = getLinkTokenResult();
  return tokenResult ? tokenResult.UserRole : null;
}

export const getLinkToken = () => {
  const user = oauthCodeFlow.userAvailable();
  return user ? user.access_token : null;
};

export function getLinkTokenResult() {
  const token = getLinkToken();
  return token ? decodeToken(token) : null;
}

export function urlBase64Decode(str: string) {
  let output = str.replace(/-/g, "+").replace(/_/g, "/");
  switch (output.length % 4) {
    case 0: {
      break;
    }
    case 2: {
      output += "==";
      break;
    }
    case 3: {
      output += "=";
      break;
    }
    default: {
      try {
        throw new Error("Illegal base64url string!");
      } catch (e) {
        alert(e);
      }
    }
  }
  return decodeURIComponent(window.atob(output));
}

export function decodeToken(token: string) {
  const parts = token.split(".");
  if (parts.length !== 3) {
    throw new Error("JWT must have 3 parts");
  }
  const decoded = urlBase64Decode(parts[1]);
  if (!decoded) {
    throw new Error("Cannot decode the token");
  }
  return JSON.parse(decoded);
}

export async function signIn() {
  return oauthCodeFlow.signinRedirect();
}

export function shareActiveBrowserInfo(event: any) {
  if (!event) {
    event = window.event;
  } // ie suq

  if (event.key === "getActiveBrowerInfo" && event.newValue) {
    // another tab asked for the sessionStorage value
    const sessionValue = sessionStorage.getItem("MTM-Link_active-browser");
    console.log(
      "index.html Another tab asked for the MTM-Link_active-browser value:",
      sessionValue
    );

    sessionValue &&
      localStorage.setItem("MTM-Link_active-browser", sessionValue);
  } else if (event.key === "MTM-Link_active-browser" && event.newValue) {
    // another tab sent the value <- get it
    console.log(
      "index.html",
      "Another tab sent the MTM-Link_active-browser value:",
      event.newValue
    );
    sessionStorage.setItem("MTM-Link_active-browser", event.newValue);
    // After populating the value in the the current tab's session storage, remove it from localstorage
    setTimeout(function() {
      localStorage.removeItem("MTM-Link_active-browser");
    });
  }
}
